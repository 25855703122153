<template>
  <div class="container-fluid position-relative">
    <div class="d-flex justify-content-between align-items-center mb-3 pt-4">
      <h1 class="font-weight-600 m-0">Alerts</h1>
      <router-link :to="{ name: 'Message Templates' }">
        <base-button class="btn-secondary-action button-font title-btn-padding">Message Template</base-button>
      </router-link>
    </div>
    <div class="tabls-container mt-3" v-loading="loaders.alerts">
      <el-tabs
        class="tab_main"
        type="border-card"
        v-model="request.tab"
        @tab-click="tabClick"
      >
        <el-tab-pane label="Nutrition" :disabled="loaders.alerts">
          <div v-if="request.tab == 0">
            <alerts-table
              ref="selectionTable"
              :tableData="response.alerts"
              :tableColumns="tableColumns"
              :pagination="response.pagination"
              :parentSearch="request.searchQuery"
              :categories="response.categories"
              :defaultCategories="response.defaultCategories"
              :filtersCount="filtersCount"
              @changed-page="changePage"
              @search="searchAlerts"
              @onEdit="promptEdit"
              @onRemove="promptRemove"
              @alertsMultiSelect="alertsMultiSelect"
            >
              <alert-dropdowns
                :duration="request.duration"
                :alerts="request.alertTypes[0]"
                :stages="request.stages"
                :selectedStage="request.selectedStage"
                @duration-change="durationChange"
                @alert-change="alertChange"
                @stage-change="stageChange"
                @onRemove="promptRemove"
              >
              </alert-dropdowns>
              <template v-slot:deleteButton>
                <base-button
                  class="mt-0 mb-4 pl-5 pr-5 multiselect-btn bg-transparent delete-btn"
                  @click="promptMultiRemove">
                  Delete
                </base-button>
              </template>
              <template v-slot:messageButton>
                <base-button
                  class="mt-0 mb-4 mr-auto pl-5 pr-5 multiselect-btn bg-transparent text-black-50"
                  @click="promptEdit(request.alertsToDelete)">
                  Message
                </base-button>
              </template>
            </alerts-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Newly Connected" :disabled="loaders.alerts">
          <div v-if="request.tab == 1">
            <alerts-table
              ref="selectionTable"
              :tableData="response.alerts"
              :tableColumns="tableColumns"
              :pagination="response.pagination"
              :parentSearch="request.searchQuery"
              :categories="response.categories"
              :defaultCategories="response.defaultCategories"
              :filtersCount="filtersCount"
              @changed-page="changePage"
              @search="searchAlerts"
              @onEdit="promptEdit"
              @onRemove="promptRemove"
              @alertsMultiSelect="alertsMultiSelect"
            >
              <alert-dropdowns
                :duration="request.duration"
                :alerts="request.alertTypes[1]"
                :stages="request.stages"
                :selectedStage="request.selectedStage"
                @duration-change="durationChange"
                @alert-change="alertChange"
                @stage-change="stageChange"
              ></alert-dropdowns>
              <template v-slot:deleteButton>
                <base-button
                  class="mt-0 mb-4 pl-5 pr-5 multiselect-btn bg-transparent delete-btn"
                  @click="promptMultiRemove">
                  Delete
                </base-button>
              </template>
              <template v-slot:messageButton>
                <base-button
                  class="mt-0 mb-4 mr-auto pl-5 pr-5 multiselect-btn bg-transparent text-black-50"
                  @click="promptEdit(request.alertsToDelete)">
                  Message
                </base-button>
              </template>
            </alerts-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Surgery" :disabled="loaders.alerts">
          <div v-if="request.tab == 2">
            <alerts-table
              ref="selectionTable"
              :tableData="response.alerts"
              :tableColumns="tableColumns"
              :pagination="response.pagination"
              :parentSearch="request.searchQuery"
              :categories="response.categories"
              :defaultCategories="response.defaultCategories"
              :filtersCount="filtersCount"
              @changed-page="changePage"
              @search="searchAlerts"
              @onEdit="promptEdit"
              @onRemove="promptRemove"
              @alertsMultiSelect="alertsMultiSelect"
            >
              <alert-dropdowns
                :duration="request.duration"
                :alerts="request.alertTypes[2]"
                :stages="request.stages"
                :selectedStage="request.selectedStage"
                @duration-change="durationChange"
                @alert-change="alertChange"
                @stage-change="stageChange"
              ></alert-dropdowns>
              <template v-slot:deleteButton>
                <base-button
                  class="mt-0 mb-4 pl-5 pr-5 multiselect-btn bg-transparent delete-btn"
                  @click="promptMultiRemove">
                  Delete
                </base-button>
              </template>
              <template v-slot:messageButton>
                <base-button
                  class="mt-0 mb-4 mr-auto pl-5 pr-5 multiselect-btn bg-transparent text-black-50"
                  @click="promptEdit(request.alertsToDelete)">
                  Message
                </base-button>
              </template>
            </alerts-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Weight" :disabled="loaders.alerts">
          <div v-if="request.tab == 3">
            <alerts-table
              ref="selectionTable"
              :tableData="response.alerts"
              :tableColumns="tableColumns"
              :pagination="response.pagination"
              :parentSearch="request.searchQuery"
              :categories="response.categories"
              :defaultCategories="response.defaultCategories"
              :filtersCount="filtersCount"
              @changed-page="changePage"
              @search="searchAlerts"
              @onEdit="promptEdit"
              @onRemove="promptRemove"
              @alertsMultiSelect="alertsMultiSelect"

            >
              <alert-dropdowns
                :duration="request.duration"
                :alerts="request.alertTypes[3]"
                :stages="request.stages"
                :selectedStage="request.selectedStage"
                @duration-change="durationChange"
                @alert-change="alertChange"
                @stage-change="stageChange"
              ></alert-dropdowns>
              <template
                v-slot:alertMessage
              >

              </template>
              <template v-slot:deleteButton>
                <base-button
                  class="mt-0 mb-4 pl-5 pr-5 multiselect-btn bg-transparent delete-btn"
                  @click="promptMultiRemove">
                  Delete
                </base-button>
              </template>
              <template v-slot:messageButton>
                <base-button
                  class="mt-0 mb-4 mr-auto pl-5 pr-5 multiselect-btn bg-transparent text-black-50"
                  @click="promptEdit(request.alertsToDelete)">
                  Message
                </base-button>
              </template>
            </alerts-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Checklist" :disabled="loaders.alerts">
          <div v-if="request.tab == 4">
            <alerts-table
              ref="selectionTable"
              :tableData="response.alerts"
              :tableColumns="tableColumns"
              :pagination="response.pagination"
              :parentSearch="request.searchQuery"
              :categories="response.categories"
              :defaultCategories="response.defaultCategories"
              :filtersCount="filtersCount"
              @changed-page="changePage"
              @search="searchAlerts"
              @onEdit="promptEdit"
              @onRemove="promptRemove"
              @alertsMultiSelect="alertsMultiSelect"

            >
              <alert-dropdowns
                :duration="request.duration"
                :alerts="request.alertTypes[4]"
                :stages="request.stages"
                :selectedStage="request.selectedStage"
                @duration-change="durationChange"
                @alert-change="alertChange"
                @stage-change="stageChange"
              ></alert-dropdowns>
              <template v-slot:deleteButton>
                <base-button
                  class="mt-0 mb-4 pl-5 pr-5 multiselect-btn bg-transparent delete-btn"
                  @click="promptMultiRemove">
                  Delete
                </base-button>
              </template>
              <template v-slot:messageButton>
                <base-button
                  class="mt-0 mb-4 mr-auto pl-5 pr-5 multiselect-btn bg-transparent text-black-50"
                  @click="promptEdit(request.alertsToDelete)">
                  Message
                </base-button>
              </template>
            </alerts-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Vitamin" :disabled="loaders.alerts">
          <div v-if="request.tab == 5">
            <h2 class="text-center mt-4 mb-4">Coming Soon</h2>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Duplicates" :disabled="loaders.alerts">
          <div v-if="request.tab == 6">
            <duplicates></duplicates>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.modals"
        :modal-text="removeModalText"
        :removeId="request.removeId"
        @onRemove="removeAlert"
        @onRemoveMultiple="deleteAlerts"
      />

      <alerts-message-modal :alert="request.alert"
                            @formSubmit="$refs.selectionTable.clearSelection()"
                            @submitAndClear="removeAlert"
      />

    </div>
    <div class="tempClassUCFS">
      <user-category-sidebar
      />
    </div>
  </div>
</template>
<script>
import authHelper from "@/util/authHelper";

const RemoveModal = () =>
  import(
    /* webpackChunkName: "nutritionAlerts" */ "@/components/Modals/RemoveModal.vue"
    );
const AlertsTable = () =>
  import(
    /* webpackChunkName: "nutritionAlerts" */ "@/views/Components/Alerts/AlertsTable.vue"
    );
const Duplicates = () =>
  import(
    /* webpackChunkName: "nutritionAlerts" */ "@/views/Components/Alerts/Duplicates.vue"
    );
const AlertDropdowns = () =>
  import(
    /* webpackChunkName: "nutritionAlerts" */ "@/views/Components/Alerts/Dropdowns.vue"
    );

const AlertsMessageModal = () =>
  import(
    /* webpackChunkName: "nutritionAlerts" */ "@/views/Components/Modals/AlertsMessageModal.vue"
    );
import UserCategorySidebar from "../../Components/UserCategorySidebar";

export default {
  components: {
    AlertsTable,
    AlertDropdowns,
    RemoveModal,
    AlertsMessageModal,
    Duplicates,
    UserCategorySidebar
  },
  data() {
    return {
      loaders: {
        alerts: false,
        modals: false,
      },
      showDelete: false,
      removeModalText: '',
      request: {
        tab: "3",
        searchQuery: "",
        removeId: "",
        alert: '',
        duration: {
          durations: ["Past 30 days", "Past 7 days", "Past 60 days"],
          selectedDuration: 0,
          days: 30,
        },
        alertTypes: {
          0: [
            "All",
            "Hasn't logged food in 30 days",
            "Hasn't logged food in 60 days",
            "Hasn't logged food in 90 days",
          ],
          1: ["All", "Connected to program", "Considering it"],
          2: [
            "All",
            "Had sugery",
            "Surgery in 30 days",
            "6 month surgiversary!",
            "1 year surgiversary!",
            "2 year surgiversary!",
            "3 year surgiversary!",
            "4 year surgiversary!",
            "5 year surgiversary!",
          ],
          3: ["All", "Gained 5 lbs", "Gained 10 lbs", "Hit weight goal"],
          4: ["All", "Checked off item", "Nothing checked off in 2 months"],
          selectedAlertType: "",
        },
        stages: {
          '25' : 'Considering Surgery',
          '50' : 'Pre-Op',
          '75' : 'Post-Op',
          '150' : 'Non-Surgical',
        },
        selectedStage : '',
        alertType: [4],
        page: 1,
        alertsToDelete: [],
      },
      response: {
        alerts: [],
        pagination: {},
        categories: [],
        defaultCategories : [],
      },
      tableColumns: [
        {
          type: 'selection'
        },
        {
          prop: "created",
          label: "Date",
          minWidth: 160,
        },
        {
          prop: "patient_name",
          label: "Name",
          minWidth: 160,
        },
        {
          prop: "alert_msg",
          label: "Alert",
          minWidth: 300,
        },
      ],
      filtersCount : 0,
    };
  },
  computed: {
    getAlertType() {
      let tabKey = Object.keys(this.request.alertTypes).find(
        (key) => this.request.tab == key
      );
      return this.request.alertTypes[tabKey];
    },

  },
  methods: {

    getFiltersData(){
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + '/api/pre-load-filters?page=' + 'alerts'
      ).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response.categories = response.data.data.categories;
        vm.response.defaultCategories = response.data.data.defaultCategories;

        if (this.request.tab == 0) {
          // nutrition
          vm.request.selectedStage = response.data.data.defaultStage != '' ? response.data.data.defaultStage : '50';
        }

        this.$store.commit('setUserSubcategories',{categories:vm.response.categories,defaultCategories:vm.response.defaultCategories })
        let obj = [];
        vm.response.categories.forEach((category,index)=>{
          var i = vm.response.defaultCategories.findIndex(dc=>{
            if(dc.user_category_id == category.id){
              return true;
            }
          });
          if(i!=-1){
            obj.push(vm.response.defaultCategories[i].user_sub_category_id);
          }
        });
        vm.getAlerts(obj);
        this.loading = false;
      }).catch(error => {
        console.log(error.response)
      });
    },

    resetSelectedAlert() {
      this.request.alertTypes.selectedAlertType = "";
    },

    changePage(page) {
      if (page != this.request.page){
        this.request.page = page;
        this.createCategoriesArrAndApiCall();
      }
    },

    searchAlerts(searchQuery) {
      this.request.searchQuery = searchQuery;
      this.createCategoriesArrAndApiCall();
    },

        promptEdit(row) {
          this.request.alert = row;
          this.$store.commit("showAlertsModal");
        },

        promptRemove(row) {
          this.$store.commit("showRemoveModal");
          this.removeModalText = 'You want to remove this alert.';
          this.request.removeId = row.id;
        },

        promptMultiRemove() {
          this.$store.commit("showRemoveModal");
          this.removeModalText = 'You want to remove these alerts.';
          this.request.removeId = this.request.alertsToDelete;
        },

        setAlertStates(response) {
          this.response.alerts = response.data.data.data;
          this.response.pagination = response.data.data;
        },

    durationChange(durationIndex) {
      this.request.duration.selectedDuration = durationIndex;
      let duration = this.request.duration.durations[durationIndex];
      this.request.duration.days = duration.split(" ")[1];
      this.createCategoriesArrAndApiCall();
    },

        alertsMultiSelect(selectedRows) {
          if (selectedRows.length > 0) {
            this.showDelete = true;
            this.request.alertsToDelete = selectedRows;
          } else {
            this.request.alertsToDelete = [];
            this.showDelete = false;
          }
        },

        alertChange(alert) {
          this.request.alertTypes.selectedAlertType = alert;

          if (alert.toLowerCase() === "connected to program") {
            this.request.alertTypes.selectedAlertType = "Connected to your program";
          }

          if (alert.toLowerCase() === "nothing checked off in 2 months") {
            this.request.alertTypes.selectedAlertType = "Hasn't checked off any checklist item in over 2 months.";
          }

          if (alert.toLowerCase() === "checked off item") {
            this.request.alertTypes.selectedAlertType = "Checked off";
          }

      if (this.request.alertTypes.selectedAlertType === "All") {
        this.request.alertTypes.selectedAlertType = ""; // send empty string because in backend, we need to use like in query
      }
      this.createCategoriesArrAndApiCall();
    },

    stageChange(stage) {
      this.request.selectedStage = stage;
      this.createCategoriesArrAndApiCall();
    },

    tabClick() {
      if (this.request.tab == 5) return; //Temporary condition for returning because this feature will be coming soon
      this.showDelete = false;
      this.request.alertsToDelete = [];
      this.response.alerts = [];
      this.request.page = 1;
      this.loaders.alerts = true;

      if (this.request.tab == 0) {
        // nutrition
        this.request.alertType = [5];
      } else if (this.request.tab == 1) {
        // newly connected
        this.request.alertType = [2, 3];
      } else if (this.request.tab == 2) {
        // surgery
        this.request.alertType = [6];
      } else if (this.request.tab == 3) {
        // weight
        this.request.alertType = [4];
      } else if (this.request.tab == 4) {
        // checklist
        this.request.alertType = [1];
      }  else if (this.request.tab == 6) {
        // duplicates
        this.request.alertType = [6];
      } else {
        // vitamin
        console.log("vitamin");
      }
      this.resetSelectedAlert();
      this.createCategoriesArrAndApiCall();
    },

        getMessageTemplates() {
          let vm = this;
          vm.loaders.modals = true;
          vm.$store.dispatch('_getMessageTemplates').then(response => {
          }).catch(error => {
            let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
            vm.$notify.error({
              title: 'Message Templates',
              message: message
            });
          }).finally(() => {
            vm.loaders.modals = false;
          });
        },

    getAlerts(userSubCategories = null) {
      let vm = this;
      vm.loaders.alerts = true;
      const config = {
        params: {
          page: this.request.page,
          search: this.request.searchQuery,
          current_stage: this.request.selectedStage,
          type: this.request.alertType.join(','),
          duration: this.request.duration.days,
          alert: this.request.alertTypes.selectedAlertType,
          user_sub_categories: userSubCategories && userSubCategories.length > 0 ? userSubCategories : null
    }
      };
      axios.get(`${this.$store.getters.getBaseUrl}/api/alerts`,config)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.setAlertStates(response);
          vm.loaders.alerts = false;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Alerts',
            message: message
          });
          vm.loaders.alerts = false;
        })
    },

    removeAlert(id) {
      let vm = this;
      vm.loaders.modals = true;
      const config = {
        headers: {
          Authorization: `Bearer ${authHelper.getAuthToken("subUser")}`,
        },
      };
      axios
        .post(`${this.$store.getters.getBaseUrl}/api/alerts/delete`,{_method: 'PUT', id: id,}, config)
        .then(() => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.getAlerts()
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Alerts',
            message: message
          });
        })
        .finally(() => {
          vm.loaders.alerts = false;
          vm.$store.commit("hideRemoveModal");
          vm.loaders.modals = false;
        });
    },

    deleteAlerts() {
      let idsToDelete = _.map(this.request.alertsToDelete, 'id');
      this.loaders.modals = true;
      let vm = this;
      this.$store.state.modals.removeModalVisibility = true;
      axios.post(`${this.$store.getters.getBaseUrl}/api/alerts/delete`,
        {alerts: idsToDelete, _method: 'PUT'})
        .then(response => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.createCategoriesArrAndApiCall();
          vm.getAlerts();
        vm.$store.commit('hideRemoveModal');
      }).catch()
        .finally(() => {
        vm.loaders.modals = false;
      })
    },

    createCategoriesArrAndApiCall() {
      let userSubCategoriesIds = [];
      this.response.categories.forEach(category => {
        if (category.state && !isNaN(category.state)) {
          userSubCategoriesIds.push(category.state);
        }
      });
      this.getAlerts(userSubCategoriesIds);
    },
  },
  mounted() {
    this.getFiltersData();
    let vm = this;
    this.$root.$on('UserSubCategoryFilterCount',(data)=>{
      vm.filtersCount = data;
    });
    this.$root.$on('applyUserSubCategoryFilters',(data)=>{
      vm.filtersCount = data.obj ? data.obj.length : 0;
      vm.getAlerts(data.obj);
    });
    this.getMessageTemplates();
  },

  beforeDestroy() {
    this.$root.$off('UserSubCategoryFilterCount');
    this.$root.$off('applyUserSubCategoryFilters');
  },
};
</script>
<style>
@import "./alerts.scss";
</style>
<style scoped>
.tab_main {
  border: none !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}

    #multi-select-area {
      background-color: white !important;
    }

    .multiselect-btn {
      border-width: 1px;
    }

    .delete-btn {
      color: #F5365C;
      border-color: #F5365C;
    }
    </style>

